/* stylelint-disable */

import React from 'react'
import styled from 'styled-components'
import { H1 } from './Text'
import { ThemeContext } from '../context/ThemeContext'
import SplitText from './SplitText'

const StyledH1 = styled(H1)`
  color: ${props => props.theme.color};
`

export default function FrontH1({ ...props }) {
  const theme = React.useContext(ThemeContext)
  const title = props.heroTitle
  const Component = props?.component ?? StyledH1

  return (
    <Component {...props} theme={theme} aria-label={title}>
      <SplitText copy={title} role="heading" type="chars" />
    </Component>
  )
}
