import React from 'react'
import styled from 'styled-components'
import Link from './Link'
import { breakpoints, breakpointNr, fluidRange } from '../style'
import { getActiveLangPath } from './SelectLanguage'
import { ThemeContext } from '../context/ThemeContext'

export function tagsWrapperModifiers(props) {
  if (props.variant === 'small') {
    return {
      fontSize: '14px',
      [`@media ${breakpoints.large}`]: {
        fontSize: fluidRange({
          min: 14,
          max: 18,
          viewportMin: breakpointNr.large,
          viewportMax: breakpointNr.xlarge,
        }),
      },
      [`@media ${breakpoints.xlarge}`]: {
        fontSize: '18px',
      },
    }
  }

  return {
    fontSize: '16px',
    [`@media ${breakpoints.medium}`]: {
      fontSize: '22px',
    },
    [`@media ${breakpoints.large}`]: {
      fontSize: fluidRange({
        min: 22,
        max: 28,
        viewportMin: breakpointNr.large,
        viewportMax: breakpointNr.xlarge,
      }),
    },
    [`@media ${breakpoints.xlarge}`]: {
      fontSize: '28px',
    },
  }
}

const TagsWrapper = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || 'baseline'};
  justify-content: ${props => props.justifyContent || 'baseline'};
  line-height: 0.8125em;
  ${tagsWrapperModifiers}
  color: ${props => props.textColor};
`

const TagList = styled.ul`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  column-gap: ${props => `${props.columnGap}px` || '0px'};
  row-gap: ${props => props.rowGap || '0px'};
  flex-wrap: wrap;
  list-style: none;
`

const Li = styled.li`
  color: ${props => props.textColor};
  margin-bottom: 12px;
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px;
  border-radius: 24px;

  @media ${breakpoints.smallDown} {
    font-size: 12px;
  }
`

export default function Tags({
  items,
  linked = true,
  textColor,
  flexDirection,
  columnGap,
  rowGap,
  ...rest
}) {
  const theme = React.useContext(ThemeContext)

  if (!items || !items.length) {
    return null
  }

  return (
    <TagsWrapper {...rest} textColor={textColor}>
      <TagList
        flexDirection={flexDirection}
        columnGap={columnGap}
        rowGap={rowGap}
      >
        {items.map(item => (
          <Li key={item.name}>
            {linked ? (
              <Link
                href={`${getActiveLangPath()}/work?filter=${encodeURIComponent(
                  item?.name?.toLowerCase(),
                )}`}
                textColor={textColor}
                styleVariant={theme.theme}
              >
                {item.name}
              </Link>
            ) : (
              item.name
            )}
          </Li>
        ))}
      </TagList>
    </TagsWrapper>
  )
}
