import React from 'react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import styled from 'styled-components'
import { H3 } from './Text'
import Tags from './Tags'
import Image, { ImageWrapper } from './Image'
// import EmbedPlayer from './EmbedPlayer'
import Video from './Video'

import {
  cover,
  breakpoints,
  fluidRange,
  vw,
  colors,
  easings,
  durations,
} from '../style'

const Link = styled(GatsbyLink)`
  display: flex;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
`

const Article = styled.article`
  position: relative;
  flex-grow: 1;
  min-height: ${fluidRange({ min: 280, max: 320 })};

  @media ${breakpoints.medium} {
    min-height: ${vw(480)};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: ${fluidRange({ min: 16, max: 24 })};
  height: 100%;

  @media ${breakpoints.medium} {
    padding: ${vw(40)};
  }
`

const Media = styled.div`
  ${cover()}
  z-index: 1;
  overflow: hidden;
  background-color: ${props => props.bg};

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.dark};
    opacity: 0;
    transition: opacity ${durations.slow} ${easings.easeOutSine};
  }

  ${Link}:hover &,
  ${Link}:focus & {
    &::before {
      opacity: 0.8;
    }
  }

  ${ImageWrapper} {
    height: 100%;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default function Tile({
  title,
  image,
  video,
  url,
  tags,
  bg = colors.steel500,
}) {
  return (
    <Link to={url}>
      <Article>
        <Content>
          <H3 textColor={colors.light} mb={[1 / 2, 1 / 2]}>
            {title}
          </H3>
          <Tags
            items={tags}
            textColor={colors.light}
            linked={false}
            variant="small"
            columnGap={12}
          />
        </Content>
        <Media bg={bg}>
          {video && video.file?.url ? (
            <Video
              src={video.file.url}
              bg="transparent"
              style={{
                position: 'static',
              }}
            />
          ) : (
            // <video playsInline loop controls={false} autoPlay muted>
            //   <source src={video.file.url} type="video/mp4" />
            //   <p>
            //     Video could not be found.{' '}
            //     <a href="mailto:info@d13.studio">Please let me know</a>
            //   </p>
            // </video>
            <Image fluid={image?.fluid} alt="" aspectRatio="auto" />
          )}

          {/* <EmbedPlayer
              data-desktop
              src={`${video.file.url}?background=1`}
              bg="transparent"
            /> */}
        </Media>
      </Article>
    </Link>
  )
}

export const query = graphql`
  fragment TileImage on ImageSharp {
    fluid(
      quality: 80
      maxWidth: 1440
      srcSetBreakpoints: [365, 520, 724, 960, 1200, 1440]
    ) {
      ...GatsbyImageSharpFluid_withWebp
    }
  }
`
